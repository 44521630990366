export default function (query) {
  const localQuery = localStorage.getItem('query') || null
  var data = (localQuery && JSON.parse(localQuery)) || {}

  query.utm_source && (data.utm_source = query.utm_source)
  query.utm_medium && (data.utm_medium = query.utm_medium)
  query.utm_campaign && (data.utm_campaign = query.utm_campaign)
  query.utm_content && (data.utm_content = query.utm_content)
  query.utm_term && (data.utm_term = query.utm_term)
  query.referral && (data.referral = query.referral)
  query.redirect && (data.redirect = query.redirect)

  localStorage.setItem('query', JSON.stringify(data))
}
