<template>
  <div class="authorization">
    <div class="authorization__container__image">
      <img
        src="@/static/svg/page-gradient.svg"
        class="authorization__gradient"
      >
    </div>

    <div class="authorization__view">
      <Header simple />

      <AuthToWebsite
        v-if="$store.state.window.width > 1080"
        top
      />

      <div class="authorization__view__center">
        <RouterView />
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/modules/app/Header.vue'
import AuthToWebsite from '@/ui/AuthToWebsite.vue'
import setQuery from '@/utils/query'

export default {
  components: {
    Header,
    AuthToWebsite
  },
  mounted () {
    setQuery(this.$route.query)
  }
}
</script>

<style lang="scss" scoped>
.authorization {
  position: relative;
  min-height: 100vh;
  width: 100%;
  padding: 0 10px;
  background: rgb(240, 242, 248);

  .authorization__container__image {
    position: absolute;
    right: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;

    img.authorization__gradient {
      position: absolute;
      top: 0;
      right: 0;
      max-height: 100%;
      z-index: 1;
    }
  }

  .authorization__view {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 2;

    .authorization__view__center {
      padding: 0 0 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      @media screen and (max-width: 768px) {
        padding: 30px 7px;
      }
    }
  }
}
</style>
